<template>
	<b-tab class="article-tab" :title="this.detailTitle" :active="isActive">
		<b-modal
			v-model="showNoteModal"
			:title="$t('resultoflinkche')"
			size="lg"
			no-close-on-backdrop
			centered
			content-class="links-modal"
		>
			<span>{{ this.noteText }}</span>
			<template v-slot:modal-footer="{}">
				<megau-button
					icon="check"
					:title="$t('ok2687')"
					@handleClick="showNoteModal = false"
					classprop="btn btn-success"
				></megau-button>
			</template>
		</b-modal>
		<vue-form-generator :schema="schema" :model="model" :options="formOptions" ref="detailForm"></vue-form-generator>
		<div class="form-generator-actions">
			<megau-button
				classprop="btn btn-success"
				icon="check"
				:title="$t('savechanges2578')"
				@handleClick="insertOrUpdate()"
			></megau-button>
			<megau-button
				classprop="btn btn-success"
				icon="check"
				:title="$t('checklinksinthe')"
				@handleClick="checkLinks()"
			></megau-button>
			<megau-button
				v-show="showDeleteButton"
				classprop="btn btn-danger"
				icon="times"
				:title="$t('delete2169')"
				@handleClick="openModal()"
			></megau-button>
		</div>
	</b-tab>
</template>
<script>
import vue from 'vue';
import { mapGetters } from 'vuex';

import { detailModel } from './seo-page-article.models';
import service from '../seo-page.service';
import formFields from './seo-page-article.fields';

import * as links from '../../.././router/links';
import serviceCommon from '../../../services/service/common.service';
import loading from '../../../mixins/loading.mixin';
import http from '../../../services/helpers/http';
import auth from '../../../services/auth.service';
import enums from '../../../services/helpers/enums';

import vueFormGenerator from 'vue-form-generator';
vue.use(vueFormGenerator);

export default {
	name: 'SeoArticleDetail',
	mixins: [loading],
	props: {
		SeoArticle: { type: Object },
	},
	data() {
		return detailModel();
	},
	computed: {
		isActive() {
			return this.model.SparticleId == 0 ? true : false;
		},
		detailTitle() {
			return this.model.SparticleCaption ? this.$i18n.t('article') : this.$i18n.t('newarticle');
		},
		language: function() {
			return this.$i18n.locale;
		},
	},
	created() {
		this.model = this.SeoArticle;
		formFields.SparticleIsVerified.user = this.model.SparticleVerifiedByUserObject;
		formFields.SparticleIsVerified.date = this.model.SparticleDateVerified;
		service.articleFormTranslations(formFields, this.$i18n);

		var res = vueFormGenerator.validators.resources;
		res.fieldIsRequired = this.$i18n.t('amistake!');
		this.showDeleteButton = auth.IsAllowedForUser(enums.role.Admin) && this.model.SparticleId > 0;
	},
	watch: {
		language: function() {
			service.articleFormTranslations(formFields, this.$i18n);
		},
	},
	methods: {
		insertOrUpdate(closeDetail) {
			serviceCommon.insertOrUpdate(this, this.model.SparticleId, this.controllerName, this.emitToTabs);
		},
		emitToTabs() {
			this.stopLoading();
			this.$emit('dataChanged');
		},
		deleteThisItem(id) {
			serviceCommon.deleteDetailItem(id, this.controllerName, this.emitToTabs);
		},
		openModal() {
			serviceCommon.openDeleteModal(this.model.SparticleId, this.$modal, this.deleteThisItem);
		},
		async checkLinks() {
			this.startLoading();
			let badLinksList = await http.post('LinksChecker/CheckBadLinksFromSeo', {
				SpageId: this.model.SpageId,
				SparticleText: this.model.SparticleText,
				LanguageCode: this.$i18n.locale,
			});
			this.stopLoading();
			if (badLinksList.data.length) {
				this.noteText = badLinksList.data;
				this.showNoteModal = true;
			}
		},
	},
};
</script>
<style lang="scss">
.article-tab {
	.field-html-text-editor {
		.ck-content {
			width: 955px !important; // nastavene podla sirky clanku na portali
		}
		.ck-sticky-panel__content_sticky,
		.ck-sticky-panel__content {
			width: 955px !important; // nastavene podla sirky clanku na portali
		}
	}
}
.not-scrollable {
	overflow: auto;
	height: auto;
}
.links-modal {
	.modal-body {
		white-space: pre-line;
	}
}
</style>
