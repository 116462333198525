import { render, staticRenderFns } from "./seo-page-article.detail.vue?vue&type=template&id=698cedec&"
import script from "./seo-page-article.detail.vue?vue&type=script&lang=js&"
export * from "./seo-page-article.detail.vue?vue&type=script&lang=js&"
import style0 from "./seo-page-article.detail.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\client\\Admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('698cedec')) {
      api.createRecord('698cedec', component.options)
    } else {
      api.reload('698cedec', component.options)
    }
    module.hot.accept("./seo-page-article.detail.vue?vue&type=template&id=698cedec&", function () {
      api.rerender('698cedec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/seo-pages/seo-page-article/seo-page-article.detail.vue"
export default component.exports