<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		tooltipMsg="manaulforseopag"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	>
		<template #header>
			{{ $t('detailofarticle') }}
			<b-link target="_blank" :href="`${model.UrlWithDomain}`">{{ model.SpageMetaTitle }}</b-link>
			<label>&nbsp;{{ $t('id') }}&nbsp;{{ model.SpageId }}</label>
		</template>

		<template #afterForm>
			<seo-page-article-tabs
				v-if="canShowArticle"
				:SeoPageId="parseInt(model.SpageId)"
				:CountryId="model.CountryId"
				:SpageLanguage="model.SpageLanguage"
			></seo-page-article-tabs>
		</template>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import serviceDictionary from '@/services/service/dictionary.service';
import serviceCommon from '@/services/service/common.service';
import { model, fields } from './seo-page.form-data';
import seoPageArticleTabs from './seo-page-article/seo-page-article.tabs';

export default {
	components: {
		DetailPage,
		seoPageArticleTabs,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'SeoPage',
			routeParam: 'SeoPageId',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	computed: {
		showDeleteButton() {
			return this.detailEntityId && auth.IsAllowedForUser(enums.role.Admin);
		},

		canShowArticle() {
			return this.model.SpageId > 0;
		},
	},

	methods: {
		async loadResources() {
			const seoGroupEnum = await serviceDictionary.getSeoGroupEnum();
			const countries = this.$store.state.country.data;

			this.updateSchemaFields({
				CountryId: { values: this.$store.getters.publicCountries },
				SpageLanguage: { values: countries.map((v) => ({ LanguageCode: v.LanguageCode.toUpperCase() })) },
				SpgroupId: { values: seoGroupEnum.vfgData },
			});
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.SpageId, this.$modal, this.deleteModel);
		},
	},
};
</script>