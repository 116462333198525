import validatorCommon from '../../services/helpers/validator.common';
import i18n from '../../services/lang';
import http from '../../services/helpers/http';

const validators = {
	async validatePageUrl(value, required, model) {
		let res = validatorCommon.checkEmpty(value, required);
		if (res) return res;

		let regex = /[À-ž\s]/;
		if (regex.test(value)) {
			console.log(value);
			return [i18n.t('amistake!')];
		}

		if (model.CountryId && model.SpageLanguage) {
			let existUrl = await http.get('SeoPage/CheckExistsUrl', {
				seoPageId: model.SpageId,
				seoPageUrl: value,
				seoPageCountryId: model.CountryId ? model.CountryId : 0,
				seoPageLang: model.SpageLanguage ? model.SpageLanguage : '',
			});

			if (existUrl.data) {
				return [i18n.t('urlnamemustbeor')];
			}
		} else {
			return [i18n.t('chyba vyplnene jazyk a krajina - slovko')];
		}

		return null;
	},
};

export default validators;
