import vueFormGenerator from 'vue-form-generator';
import validator from './seo-page.validators';

export const model = {
	SpageId: 0,
	SpageUrl: null,
	SpageCaptionH1: null,
	SpageMetaTitle: null,
	SpageMetaDescription: '',
	SpageLanguage: null,
	CountryId: null,
	SpgroupId: null,
};

export const fields = [
	{
		fields: [
			{
				type: 'select',
				model: 'SpageLanguage',
				values: [],
				required: true,
				validator: vueFormGenerator.validators.string,
				selectOptions: {
					value: 'LanguageCode',
					name: 'LanguageCode',
				},
				i18n: {
					label: 'languange:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'select',
				model: 'CountryId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					value: 'Id',
					name: 'Name',
				},
				i18n: {
					label: 'country:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'select',
				model: 'SpgroupId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					value: 'SpgroupId',
					name: 'SpgroupName',
				},
				i18n: {
					label: 'group2671',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'SpageUrl',
				min: 1,
				validateDebounceTime: 500,
				required: true,
				validator: validator.validatePageUrl,
				i18n: {
					label: 'url2672',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'SpageMetaTitle',
				min: 1,
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'metatitle2673',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'SpageMetaDescription',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'metadescription',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'SpageCaptionH1',
				min: 1,
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'htitle2675',
				},
			},
		],
	},
];
