import vueFormGenerator from 'vue-form-generator';

var SparticleCaption = {
	type: 'input',
	inputType: 'text',
	model: 'SparticleCaption',
	label: '',
	min: 1,
	required: true,
	validator: vueFormGenerator.validators.string,
};

var SparticleText = {
	type: 'html-text-editor',
	model: 'SparticleText',
	label: '',
	imageUpload: true,
};

var SparticleIsVerified = {
	type: 'verified-switch',
	label: '',
	model: 'SparticleIsVerified',
	user: '',
	date: null,
	textOn: '',
	textOff: '',
};

var SparticleIsVisible = {
	type: 'verified-switch',
	label: '',
	model: 'SparticleIsVisible',
	textOn: '',
	textOff: '',
};

var SparticleDateCreated = {
	type: 'article-created',
	model: 'SparticleDateCreated',
	label: '',
	visible: function(model) {
		return model.SparticleDateCreated && model.SparticleCreatedByUser;
	},
};

function groups() {
	return [
		{
			fields: [SparticleCaption, SparticleText, SparticleDateCreated, SparticleIsVerified, SparticleIsVisible],
		},
	];
}

export default {
	groups,
	SparticleCaption,
	SparticleText,
	SparticleDateCreated,
	SparticleIsVerified,
	SparticleIsVisible,
};
