var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.isLoaded
    ? _c(
        "div",
        { staticClass: "bcard-wrapper" },
        [
          _c(
            "div",
            { staticClass: "d-flex mb-2 justify-content-between" },
            [
              _c("h4", [_vm._v(_vm._s(_vm.detailTitle))]),
              _c("megau-button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: this.showAddNewButton,
                    expression: "this.showAddNewButton",
                  },
                ],
                attrs: {
                  classprop: "btn btn-success ml-2",
                  icon: "plus",
                  titlePosition: "right",
                  title: _vm.$t("addanewarticle"),
                },
                on: {
                  handleClick: function ($event) {
                    return _vm.insertNewTab()
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "b-tabs",
            { attrs: { "content-class": "mt-3" } },
            [
              _vm._l(this.SpArticleList, function (article) {
                return [
                  _c("seo-page-article-tab", {
                    key: _vm.articleKey(article.SparticleId),
                    attrs: { SeoPageId: _vm.SeoPageId, SeoArticle: article },
                    on: {
                      dataChanged: function ($event) {
                        return _vm.fetchData()
                      },
                    },
                  }),
                ]
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }