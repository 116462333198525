var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("detail-page", {
        ref: _vm.detailPageRefName,
        attrs: {
          formModel: _vm.model,
          formSchema: _vm.schema,
          tooltipMsg: "manaulforseopag",
        },
        on: {
          saveForm: function ($event) {
            return _vm.saveModel($event)
          },
          closeDetail: function ($event) {
            return _vm.redirectToTable()
          },
          delete: function ($event) {
            return _vm.openDeleteModal()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v(
                    "\n\t\t" + _vm._s(_vm.$t("detailofarticle")) + "\n\t\t"
                  ),
                  _c(
                    "b-link",
                    {
                      attrs: {
                        target: "_blank",
                        href: "" + _vm.model.UrlWithDomain,
                      },
                    },
                    [_vm._v(_vm._s(_vm.model.SpageMetaTitle))]
                  ),
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("id")) +
                        " " +
                        _vm._s(_vm.model.SpageId)
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "afterForm",
              fn: function () {
                return [
                  _vm.canShowArticle
                    ? _c("seo-page-article-tabs", {
                        attrs: {
                          SeoPageId: parseInt(_vm.model.SpageId),
                          CountryId: _vm.model.CountryId,
                          SpageLanguage: _vm.model.SpageLanguage,
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3152789676
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }