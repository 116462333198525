var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-tab",
    {
      staticClass: "article-tab",
      attrs: { title: this.detailTitle, active: _vm.isActive },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("resultoflinkche"),
            size: "lg",
            "no-close-on-backdrop": "",
            centered: "",
            "content-class": "links-modal",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                return [
                  _c("megau-button", {
                    attrs: {
                      icon: "check",
                      title: _vm.$t("ok2687"),
                      classprop: "btn btn-success",
                    },
                    on: {
                      handleClick: function ($event) {
                        _vm.showNoteModal = false
                      },
                    },
                  }),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showNoteModal,
            callback: function ($$v) {
              _vm.showNoteModal = $$v
            },
            expression: "showNoteModal",
          },
        },
        [_c("span", [_vm._v(_vm._s(this.noteText))])]
      ),
      _c("vue-form-generator", {
        ref: "detailForm",
        attrs: {
          schema: _vm.schema,
          model: _vm.model,
          options: _vm.formOptions,
        },
      }),
      _c(
        "div",
        { staticClass: "form-generator-actions" },
        [
          _c("megau-button", {
            attrs: {
              classprop: "btn btn-success",
              icon: "check",
              title: _vm.$t("savechanges2578"),
            },
            on: {
              handleClick: function ($event) {
                return _vm.insertOrUpdate()
              },
            },
          }),
          _c("megau-button", {
            attrs: {
              classprop: "btn btn-success",
              icon: "check",
              title: _vm.$t("checklinksinthe"),
            },
            on: {
              handleClick: function ($event) {
                return _vm.checkLinks()
              },
            },
          }),
          _c("megau-button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showDeleteButton,
                expression: "showDeleteButton",
              },
            ],
            attrs: {
              classprop: "btn btn-danger",
              icon: "times",
              title: _vm.$t("delete2169"),
            },
            on: {
              handleClick: function ($event) {
                return _vm.openModal()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }